export function sidebarFilters(): void {
    // Offer filters scroll
    const hideFilterElements = document.querySelectorAll<HTMLElement>('.filter-item.scroll');
    if (hideFilterElements !== null) {
        hideFilterElements.forEach(item => {
            const listItems = item.querySelectorAll('.filter-item label');
            for (let item = 5; item < listItems.length; item++) {
                listItems[item].classList.add('hide');
            }
        });
    }

    const showMore = document.querySelectorAll<HTMLElement>('.show-more');
    showMore.forEach(item => {
        item.addEventListener('click', e => {
            const listItems = (e.target as HTMLElement).closest('.filter-item.scroll');
            if (listItems !== null) {
                const showElement = listItems.querySelectorAll('.filter-item label');
                for (let item = 5; item < showElement.length; item++) {
                    showElement[item].classList.toggle('hide');
                }
                if (listItems.firstElementChild !== null) {
                    listItems.firstElementChild.classList.toggle('hide-onScroll');
                }
            }
            if (item.dataset.show === 'false') {
                item.textContent = 'Отмена';
                item.dataset.show = 'true';
            } else {
                item.textContent = 'Показать еще';
                item.dataset.show = 'false';
            }
        });
    });
}
