import { AxiosError, AxiosResponse } from 'axios';
import { apiClient } from '../../../api';

interface GetPhoneResponse {
    phone: string;
    telegram: string;
    skype: string;
}

const fetchPostPhone = async (userId: string): Promise<AxiosResponse<GetPhoneResponse>> => {
    return await apiClient.get(`/user/${userId}/contacts`);
};

export async function showPhone(): Promise<void> {
    document.querySelectorAll<HTMLDivElement>('[data-user-phone]').forEach(item => {
        item.addEventListener('click', () => {
            const userId = item.dataset['userPhone'];

            if (userId) {
                fetchPostPhone(userId)
                    .then(response => {
                        if (response.status === 200) {
                            item.textContent = response.data.phone;
                            item.className = 'phone';
                        } else if (response.status === 429) {
                            item.textContent = 'Сишком много запросов';
                        }
                    })
                    .catch((error: AxiosError) => {
                        item.textContent = error.message;
                        throw new Error(error.message);
                    });
            }
        });
    });
}
